<template>
  <div id="gjs"></div>
</template>

<script>
import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'
import gjsBlockBasic from "grapesjs-blocks-basic";
import grapesjsPresetWebpage from "grapesjs-preset-webpage";
import grapesjsBlocksBootstrap4 from 'grapesjs-blocks-bootstrap4';
export default {
  name: 'gjs',
  props: ['item'],
  mounted(){
    let editor = grapesjs.init({
      container: '#gjs',
      components: this.item.body,
      height: '900px',
      width: '100%',
      // plugins: [gjsBlockBasic],
      plugins: [grapesjsBlocksBootstrap4,grapesjsPresetWebpage,gjsBlockBasic],
      storageManager: false,
      // storageManager: {
      //   id: 'gjs-',
      //   type: 'local',
      //   contentTypeJson: true,
      //   autoload: true,
      //   autosave: true,
      //   storeComponents: true,
      //   storeStyles: true,
      //   storeHtml: true,
      //   storeCss: true,
      // },
      deviceManager: {
        devices:
            [
              {
                id: 'desktop',
                name: 'Desktop',
                width: '',
              },
              {
                id: 'tablet',
                name: 'Tablet',
                width: '768px',
                widthMedia: '992px',
              },
              {
                id: 'mobilePortrait',
                name: 'Mobile portrait',
                width: '320px',
                widthMedia: '575px',
              },
            ]
      },
      pluginsOpts: {
        grapesjsPresetWebpage: {
          blocksBasicOpts: {
            blocks: ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image', 'video'],
            flexGrid: 1,
          },
          blocks: ['link-block', 'quote', 'text-basic'],
        },
      },
      canvas: {
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css',
          'http://ms-website.serversidelogics.com/public/assets/demos/default/css/plugins.css',
          'http://ms-website.serversidelogics.com/public/assets/demos/default/css/components.css',
          'http://ms-website.serversidelogics.com/public/assets/demos/default/css/themes/default.css',
          'http://ms-website.serversidelogics.com/public/assets/demos/default/css/custom.css',
        ],
        scripts: [
          'https://cdn.jsdelivr.net/npm/jquery@1.12.4/dist/jquery.min.js',
          'http://ms-website.serversidelogics.com/public/assets/plugins/jquery-migrate.min.js',
          'https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js',
          'https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/js/bootstrap.min.js'
        ],
      }
    })
    let that  = this;
    editor.on('update', function (){
      that.$emit('gjsUpdate', editor.getHtml())
    })
  }
}
</script>
<style>
</style>